export const editorLangTrStrs = {
    welcomNewProject : 'Yeni projenize hoş geldiniz',
    pls_add_media:'Lütfen projenize medya ekleyin',
    add_video:'Video Ekle',
    add_audio:'Ses Ekle',
    add_image:'Görüntü Ekle',
    add_webcam:'Web Kamerası Yakalama',
    add_screen:'Ekran Yakalama',
    add_webcam_screen:'Web Kamerası & Ekran Yakalama',
    capture_screen_camera:'Ekranınızı ve kamerasını yakalayın!',
    video_captureDevice:'Video Yakalama Cihazı',
    resolution: 'Çözünürlük',
    audio_captureDevice: 'Ses Yakalama Cihazı',
    resize_video: 'Videoyu Boyutlandır',
    rotate_video: 'Videoyu Döndür',
    loop_video: 'Videoyu Döngüye Al',
    joinmarge_video: 'Videoya Katıl/Birleştir',
    cut_video: 'Videoyu Kes',
    crop_video: 'Videoyu Kırp',
    translate_video: 'Videoyu Çevir',
    transcripbe_video: 'Videoyu Altyazı',
    auto_subtitles: 'Otomatik Altyazılar',
    add_subtitle_video: 'Videoya Altyazı Ekle',
    compress_video: 'Videoyu Sıkıştır',
    convert_video: 'Videoyu Dönüştür',
    project_not_accessible: 'Proje erişilemez',
    leave: 'Ayrıl',
    cancel: 'İptal',
    okay: 'Tamam',
    stop: 'Durdur',
    record: 'Kaydet',
    ongoing_upload_project_str: 'Bu projeye devam eden bir yükleme var.',
    leaving_page_sure: 'Bu sayfadan ayrılıyorsunuz, emin misiniz?',
    uploading_files: 'Dosyalar Yükleniyor',
    record_screen_and_upload: 'Ekranınızı kaydedin ve yükleyin.',
    show_defaul_sharingMenu: 'Varsayılan paylaşım Menü Öğelerini göster',
    prefer_share_browser: 'Bir tarayıcı sekmesini paylaşmayı tercih et',
    prefer_share_window: 'Bir pencereyi paylaşmayı tercih et',
    prefer_share_entireScreen: 'Tüm ekranı paylaşmayı tercih et',
    record_webcam_and_upload: 'Web kameranızı kaydedin ve yükleyin.',
    sign_signup_to_uploadVideos: 'Video yüklemek için lütfen giriş yapın veya kayıt olun',
    sizelimir_or_timelimit: '250 Mbayttan büyük veya 5 dakikadan uzun',
    screen_capture_preferences: 'Ekran Yakalama Tercihleri',
    is_uploading: 'Yükleniyor',
    monthly: 'Aylık',
    annually: 'Yıllık',
    most_popular: 'En Popüler',
    export: 'Dışa Aktar',
    bitrate: 'Bit Hızı',
    frame_rate_fps: 'Kare Hızı (FPS)',
    produce: 'Üret',
    setup: 'Kurulum',
    template: 'Şablon',
    templates: 'Şablonlar',
    media: 'Medya',
    stock: 'Stok',
    translations: 'Çeviriler',
    subtitles: 'Altyazılar',
    text_to_speech: 'Metinden Sese',
    transitions: 'Geçişler',
    text: 'Metin',
    emojis: 'Emojiler',
    filters: 'Filtreler',
    draw: 'Çiz',
    edit_subtitle: 'Altyazıyı Düzenle',
    add_new_subtitle: 'yeni altyazı ekle',
    select_brush_stock_start_draw: 'Çizmeye başlamak için bir fırça darbesi seçin',
    brush_stroke: 'Fırça Darbesi',
    round: 'Yuvarlak',
    sliced: 'Dilimlenmiş',
    color: 'Renk',
    opacity: 'Opaklık',
    size: 'Boyut',
    duration: 'Süre',
    add_new_line: 'Yeni Satır Ekle',
    all_types: 'Tüm Türler',
    load_more: 'Daha Fazla Yükle',
    upload_video: 'Video Yükle',
    upload_audio: 'Ses Yükle',
    upload_image: 'Görüntü Yükle',
    create_webcam_recording: 'Web Kamerası Kaydı Oluştur',
    create_screen_capture_recording: 'Ekran Yakalama Kaydı Oluştur',
    create_webcam_screen_recording: 'Web Kamerası & Ekran Kaydı Oluştur',
    project_asset: 'Proje Varlığı',
    you_want_add_translations: 'Çevirileri nasıl eklemek istersiniz?',
    uploader: 'Yükleyici',
    auto: 'OTOMATİK',
    auto_translations: 'Otomatik Çeviriler',
    automatically_add_transllations_video: 'Videoya otomatik olarak çeviriler ekle',
    wait_moments_subtitles_being: 'Altyazı hazırlanırken birkaç dakika bekleyin',
    select_language_detect: 'Algılanacak dili seçin',
    disable_translate: 'Çeviriyi Devre Dışı Bırak',
    select_language_select: 'Çevrilecek dili seçin',
    transition_duration: 'Geçiş Süresi',
    coming_soon: 'Yakında',
    language: 'Dil',
    speaking_rate_defaul: 'Konuşma Hızı (varsayılan 1)',
    pitch_defaul: 'Ton (varsayılan 0)',
    volume_gain_default: 'Ses Kazancı (varsayılan 0)',
    create_text_speech: 'Metinden Sese Oluştur',
    add_text: 'Metin Ekle',
    text: 'Metin',
    start: 'Başlangıç',
    end: 'Bitiş',
    text_style: 'Metin Stili',
    background_color: 'Arka Plan Rengi',
    add_new_text_field: 'Yeni Metin Alanı Ekle',
    how_add_subtitles: 'Altyazıları nasıl eklemek istersiniz',
    automatically_subtitles_video: 'Videoya otomatik olarak altyazı ekle',
    text_color: 'Metin Rengi',
    preview_text: 'Metni Önizle',
    search: 'Ara',
    orientation: 'Yönlendirme',
    next: 'Sonraki',
    previous: 'Önceki',
    locale: 'Yerel Ayar',
    landscape: 'Yatay',
    portrait: 'Dikey',
    square: 'Kare',
    time: 'Zaman',
    background: 'Arka Plan',
    project_setting: 'Proje Ayarları',
    use_template_project: 'Şablon proje olarak kullan',
    visibility: 'Görünürlük',
    category: 'Kategori',
    capture_thumbnail: 'Küçük Resim Yakala',
    brand_kit: 'Marka Kiti',
    video_placeholder: 'VideoYerTutucu2.mp4',
    replace_video: 'Videoyu Değiştir',
    transformations: 'Dönüşümler',
    fill_canvas: 'Tuvali Doldur',
    fit_canvas: 'Tuvali Sığdır',
    round_corners: 'Yuvarlak Köşeler',
    audio: 'Ses',
    detach_audio: 'Sesi Ayır',
    cleanAudio: 'TemizSes',
    upgrade_basic_unlock: 'Temel özellikleri kilidini açmak için yükseltin',
    remove_background_noise_enhance_audio_qty: 'Arka plan gürültüsünü kaldırın ve ses kalitesini artırın',
    color_correction: 'Renk Düzeltme',
    reset: 'Sıfırla',
    exposure: 'Pozlama',
    brightness: 'Parlaklık',
    contract: 'Kontrast',
    saturation: 'Doygunluk',
    sharpen: 'Keskinleştir',
    blur: 'Bulanıklaştır',
    noise: 'Gürültü',
    trending: 'TREND',
    custom: 'ÖZEL',
    portrait: 'DİKEY',
    landscape: 'YATAY',
    settings: 'Ayarlar',
    magic_draw: 'Sihirli Çizim',
    tools: 'Araçlar',
    brush_size: 'Fırça Boyutu',
    save: 'Kaydet',
    clear: 'Temizle',
    view_all: 'Tümünü Gör',
    save_your_project_later: 'Projeyi daha sonra kaydet',
    sign_in: 'giriş yap',
    sign_up: 'üye ol',
    sign_up_home: 'Üye',
    redo: 'YİNELE',
    undo: 'GERİ AL',
    collaborate: 'İŞBİRLİĞİ YAP',
    enter_email_share_project: 'Projeyi paylaşmak için e-posta girin',
    share_project: 'Projeyi Paylaş',
    create_new_workspace: 'Yeni Çalışma Alanı Oluştur',
    audio_noise_remove: 'Ses Gürültüsü Kaldırma',
    media_upload: 'Medya Yükleme',
    draw_color: 'Çizim Rengi',
    tsl_none: 'yok',
    tsl_fade: 'Solma',
    tsl_book_flip: 'Kitap Çevirme',
    tsl_disolve: 'Dağılma',
    tsl_doorway: 'Kapı Ağzı',
    tsl_blur: 'Bulanıklık',
    tsl_push: 'İtme',
    tsl_slide: 'Kaydırma',
    tsl_wipe: 'Silme',
    tsl_zoom_in: 'Yakınlaştır',
    tsl_zoom_out: 'Uzaklaştır',
    flt_None: 'Yok',
    flt_Darker: 'Koyulaştır',
    flt_BlacknWhite: 'Siyah&Beyaz',
    flt_Daphne: 'Daphne',
    flt_Penelope: 'Penelope',
    flt_Phoebe: 'Phoebe',
    flt_Thalia: 'Thalia',
    flt_Chloe: 'Chloe',
    flt_Hermione: 'Hermione',
    flt_Hera: 'Hera',
    flt_Rhea: 'Rhea',
    flt_Donna: 'Donna',
    flt_Martina: 'Martina',
    flt_Gabriella: 'Gabriella',
    flt_Elisabetta: 'Elisabetta',
    flt_Isabella: 'Isabella',
    flt_Luna: 'Luna',
    flt_Viola: 'Viola',
    flt_Zita: 'Zita',
    flt_Donata: 'Donata',
    flt_Maddalena: 'Maddalena',
    flt_Tecla: 'Tecla',
    flt_Narcisa: 'Narcisa',
    emj_Smileys: 'Gülücükler',
    emj_Animals: 'Hayvanlar',
    emj_People: 'İnsanlar',
    emj_Jobs: 'Meslekler',
    emj_Sports: 'Sporlar',
    emj_Flags: 'Bayraklar',
    emj_Signs: 'İşaretler',
    emj_Nature: 'Doğa',
    emj_Travel: 'Seyahat',
    emj_Letters: 'Harfler',
    emj_Misc: 'Çeşitli',
    create_empty_project_templates: 'Şablonlardan Boş Bir Proje Oluştur',
    days_ago: 'gün önce',
    create_workspace: 'Çalışma Alanı Oluştur',
    workspace: 'Çalışma Alanları',
    delete_workspace: 'Çalışma Alanını Sil',
    transfer_owner: 'Sahipliği Aktar',
    can_create_new_workspace: 'Yeni çalışma alanı oluşturabilir misiniz?',
    could_delete_workspace: 'Çalışma alanını silebilir misiniz?',
    available_subtitles_time: 'Kullanılabilir Altyazı Süresi',
    available_storage_space: 'Kullanılabilir Depolama Alanı',
    available_text_to_speech: 'Kullanılabilir Metinden Sese',
    other_workspaces: 'Diğer çalışma alanları',
    members: 'Üyeler',
    add_member: 'Üye Ekle',
    remove_member: 'Üyeyi Kaldır',
    change_member_level: 'Üye Seviyesini Değiştir',
    owner: 'Sahip',
    admin: 'Yönetici',
    member: 'Üye',
    read_only: 'Sadece Okuma',
    duplicate: 'Çoğalt',
    splite: 'Böl',
    remove: 'kaldır',
    layer: 'Katman',
    volume: 'Ses Seviyesi',
    disable: 'Devre Dışı Bırak',
    deleterow: 'Satırı Sil',
    fullscreen: 'Tam Ekran',
    editsubtitle: 'Altyazıyı Düzenle',
    fit: 'Sığdır',
    green_screen: 'Yeşil Ekran',
    profile: 'Profil',
    pricing: 'Fiyatlandırma',
    login: 'Giriş',
    logout: 'Çıkış',
    get_started_free: "Başlayın-ücretsizdir",
    dashboard: 'Kontrol Paneli',
    projects: 'Projeler',
    menu_Features: 'Özellikler',
    menu_Tools: 'Araçlar',
    menu_VideoEditor: 'Video Düzenleyici',
    menu_AIVideoEditor: 'Yapay Zeka Video Düzenleyici',
    menu_AddImgToVideo: 'Videoya Resim Ekle',
    menu_AddMusicToVideo: 'Videoya Müzik Ekle',
    menu_AddTextToVideo: 'Videoya Metin Ekle',
    menu_FilterVideo: 'Video Filtrele',
    menu_AddTransitionstoVideo: 'Videoya Geçişler Ekle',
    menu_AddFreeStockVideos:'Ücretsiz Stok Videoları Ekle',
    menu_SubtitlesTranscription: 'Altyazılar & Dikte',
    menu_AddSubtitlesToVideo: 'Videoya Altyazı Ekle',
    menu_AudioToText: 'Sesten Metne',
    menu_AutoSubtitles: 'Otomatik Altyazılar',
    menu_TranscribeVideo: 'Videoyu Dikte Et',
    menu_TranslateVideo: 'Videoyu Çevir',
    menu_Toolkit: 'Araç Seti',
    menu_Crop: 'Kırp',
    menu_Cut: 'Kes',
    menu_JoinMerge: 'Birleştir/Merge',
    menu_LoopVideo: 'Videoyu Döngüye Al',
    menu_Resize: 'Boyutlandır',
    menu_Rotate: 'Döndür',
    menu_More: 'Daha Fazla',
    menu_VideoCompressor: 'Video Sıkıştırıcı',
    menu_VideoConverter: 'Video Dönüştürücü',
    menu_AIGenerated: 'Yapay Zeka ile Üretilmiş',
    menu_AIGeneratedImages: 'Yapay Zeka ile Üretilmiş Görüntüler',
    menu_AIGeneratedVideos: 'Yapay Zeka ile Üretilmiş Videolar',
    menu_AIGeneratedVoices: 'Yapay Zeka ile Üretilmiş Sesler',
    menu_Blog: 'Blog',
    menu_VIDEOOBlog: 'VIDEOO Blog',
    menu_VIDEOOHowTo: 'VIDEOO Nasıl Yapılır',
    menu_VIDEOOCareers: 'VIDEOO Kariyerleri',
    try_autosubtitles_in_english: 'İngilizce Otomatik Altyazıları Deneyin',
    try_translations_in_turkish: 'Türkçe Çevirileri Deneyin',
    try_subtitles_in_spanish: 'İspanyolca Altyazıları Deneyin',
    try_ai_video_summary: 'Yapay Zeka Tabanlı Video Özetini Deneyin',
    online_ai_video_editor: 'Çevrimiçi Yapay Zeka Tabanlı Video Düzenleyici',
    online_could_video_editor: 'Çevrimiçi Bulut Tabanlı Video Düzenleyici',
    home_free_trial_detail: '7 Gün Ücretsiz Deneme, İstediğiniz Zaman İptal',
    home_create_button_detail: 'Otomatik altyazılar, çeviri ve daha fazlası ile tek tıklamayla video oluşturma',
    create_now: 'Yeni Video Oluştur',
    home_templates_start: 'Başlamak için bir dizi şablonu keşfedin',
    try_now_required: 'Şimdi dene, hesap gerekmez',
    online_collaborative_video_editing: 'Çevrimiçi İşbirlikçi Video Düzenleme',
    online_collaborative_video_editing_detail: 'Videoo.io ile Online Bulut Tabanlı Video Düzenlemenin dünyasına dalın. Platformumuz, düzenleme şeklinizi devrim niteliğinde değiştirerek Online İşbirlikçi Video Düzenleme yetenekleri ve Bulut Tabanlı Video Düzenleme Çalışma Alanı sunar.',
    auto_subtitles_translations: 'Otomatik Altyazılar ve Çeviriler',
    auto_subtitles_translations_detail: "Profesyonel Online Video Düzenleme Yazılımımızın bir parçası olan Otomatik Altyazılar ve Çeviriler özelliği ile videolarınızı geliştirin. Videoo.io, içeriğinizi erişilebilir ve çekici hale getirmeyi basitleştiren Herkes İçin Bir Video Düzenleme Yazılımı Aracı olarak tasarlanmıştır.",
    ai_base_text_speech: 'Yapay Zeka Tabanlı Metinden Sese',
    ai_base_text_speech_detail: 'Yapay Zeka tabanlı Metinden Sese Seslerimiz, içerik oluşturucular için mükemmeldir, Youtuberlar ve Influencerlar için Video Düzenlemenin Yeniden Keşfedilmesi kavramını somutlaştırır. Videoo.io ile, senaryolarınızı etkileyici sesli anlatımlara dönüştürerek videolarınıza profesyonel bir dokunuş ekleyin ve üretim sürecinizi hızlandırın.',
    free_user_stock_video: 'Ücretsiz Kullanılabilir Stok Videolar',
    free_user_stock_video_detail: 'Videoo.io ile yüksek kaliteli görüntülerle projelerinizin öne çıkmasını sağlayan, Herkes İçin 4K Video Düzenleme Yazılımı olarak, profesyonel düzeyde düzenlemeyi tüm kullanıcılara erişilebilir hale getiren platformumuzda kullanılabilir geniş bir Ücretsiz Kullanılabilir Stok Videolar koleksiyonuna erişin.',
    share: 'Paylaş',
    unlisted_video: 'Listelenmemiş Video',
    your_video_about_ready: 'Videonuz hazır olmak üzere',
    hours: 'Saatler',
    minutes: 'Dakikalar',
    seconds: 'Saniyeler',
    want_free_stuff: 'Ücretsiz şeyler mi istiyorsunuz?',
    record_screen_webcam_both: 'Ekranınızı, web kameranızı veya her ikisini birden kaydedin',
    add_subtitle_your_video: 'Videolarınıza altyazı ekleyin',
    create_your_team_workspace: 'Takım Çalışma Alanınızı Oluşturun',
    collaborate_videos: 'Videolar üzerinde işbirliği yapın',
    generate_transcript: 'Transkript oluşturun',
    singup_get_all_this_more: 'Tüm bunları ve daha fazlasını almak için kaydolun (ücretsiz)!',
    downloading: 'İndiriliyor',
    download: 'İndir',
    reedit_video: 'Videoyu Yeniden Düzenle',
    remove_watermark: 'Filigranı Kaldır',
    already_have_account: 'Zaten hesabınız var mı?',
    link_copied: 'Link Kopyalandı',
    invitation_list: 'Davet Listesi',
    remove_invitation: 'Daveti Kaldır',
    change_workspace: ' Çalışma Alanını Değiştir',
    you_login_manage_your_profile: 'Profilinizi yönetmek için giriş yapmanız gerekmektedir',
    you_dont_have_content_here: "Burada herhangi bir içeriğiniz yok.",
    name: 'İsim',
    status: 'Durum',
    data_modified: 'Değiştirilme Tarihi',
    all_workspaces: 'Tüm Çalışma Alanları',
    delete_project: 'Projeyi Sil',
    duplicate_project: 'Projeyi Çoğalt',
    draft: 'Taslak',
    edit: 'Düzenle',
    surname: 'Soyad',
    email: 'E-posta',
    plan: 'Plan',
    market_emails: 'Pazarlama E-postaları',
    editor_notification: 'Editör Bildirimleri',
    storage: 'Depolama',
    subtitle: 'Altyazı',
    delete_account: 'Hesabı Sil',
    this_action_irreversibe: 'Bu işlem geri alınamaz',
    current_plan: 'MEVCUT PLAN',
    cancel_subscription: 'Aboneliği İptal Et',
    payment_method: 'ÖDEME YÖNTEMLERİ',
    expires: 'Bitiş Tarihi',
    make_default: 'Varsayılan Yap',
    delete: 'Sil',
    add_payment_method: 'Ödeme yöntemi ekle',
    billing_information: 'FATURA BİLGİLERİ',
    invoice_history: 'FATURA GEÇMİŞİ',
    payment: 'Ödeme',
    preferences: 'Tercihler',
    save_up_to: 'Bu Kadar Tasarruf Et:',
    your_plan: 'Planınız',
    inactive: 'Pasif',
    contact_sales: 'Satışla İletişime Geç',
    need_additional_features: 'Ek özelliklere mi ihtiyacınız var? Kurumsal planımız hakkında bize sorun'
};