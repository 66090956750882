import { useState } from "react";
import { Link } from "react-router-dom";
import { Box, Dialog, Typography, Grid } from "@mui/material";

import './newProjectPopup.scss';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import CloseIcon from '@mui/icons-material/Close';
import { GetProjectLink } from "../../axios/ApiProvider";

import { Stocks } from "../workspaces/pages/stocks/stocks";
import { Templates } from './../workspaces/pages/templates/templates';
import { Projects } from '../workspaces/pages/projects/projects'
import { Exports } from "../workspaces/pages/exports/exports";
import { Assets } from "../workspaces/pages/assets/assets";
import { Compress } from "../workspaces/pages/compress/compress";
import { Transcodes } from "../workspaces/pages/transcodes/transcodes";
import NewBadgeImage from "../../assets/image/icons/new-badge.png";
import CreateProjectImage from "../../assets/image/icons/create-project.png";
import TemplateImage from "../../assets/image/icons/templates.png";
import AssetsImage from "../../assets/image/icons/assets.png";
import CompressImage from "../../assets/image/icons/compress.png";
import TransocodeImage from "../../assets/image/icons/transcode.png";
import ExportImage from "../../assets/image/icons/exports.png";
import ProjectsImage from "../../assets/image/icons/projects.png"

export const EDIT_FRONTEND_URL = process.env.REACT_APP_BASE_FRONTEND_EDITOR_URL

export const NewProjectPopup = ({ popUp, setPopUp }) => {
  const [activeMenu, SetActiveMenu] = useState('stocks');

  const handleCreateNewProject = async () => {
    let result = await GetProjectLink();
    if (!result || result.state == 'error') window.toastr.error(result.data.message);
    else if (!!Object.keys(result.data)[0]) window.location.href = `${EDIT_FRONTEND_URL}/${Object.keys(result.data)[0]}?from=new`
  }

  return (
    <Dialog open={popUp}
      onClose={() => { setPopUp(false); }}
      className='empty-project-popup-wrapper'
      fullWidth
    >
      <Box className="ep-popup-cotain">
        <Box className="popup-close-btn" onClick={() => { setPopUp(false); }}>
          <CloseIcon />
        </Box>

        <Grid container spacing={1}>
          <Grid item xs={6} md={4}>
            <Box className="ep-popup-left-contain">
              <Typography component='h2'>New Project</Typography>
              <Box component='div' className='popup-left-menuitems-wrapper'>
                <Box component='div' className={`popup-left-menuitem`} onClick={handleCreateNewProject}>
                  <Typography component='span' sx={{ backgroundColor: 'green', color: 'white', padding: '3px 3px', borderRadius: '8px', fontWeight: 'bold', display: 'inline-block'}}>Create Project</Typography>
                  <img className="ml-1" alt="new-badge" src={NewBadgeImage} width={30} />
                  <Box component='div' className='popup-menuitem-icon' ><img src={CreateProjectImage} alt="create project" width={30} /></Box>
                </Box>

                <Box component='div'
                  onClick={() => { SetActiveMenu("stocks") }}
                  className={`popup-left-menuitem ${activeMenu === 'stocks' ? 'active' : ''}`}
                >
                  <Typography component='span'>Stock Content</Typography>
                  <Box component='div' className='popup-menuitem-icon'><img src={TemplateImage} alt="stocks" width={30} /></Box>
                </Box>

                <Box component='div'
                  onClick={() => { SetActiveMenu("templates") }}
                  className={`popup-left-menuitem ${activeMenu === 'templates' ? 'active' : ''}`}
                >
                  <Typography component='span'>Templates</Typography>
                  <Box component='div' className='popup-menuitem-icon'><img src={TemplateImage} alt="templates" width={30} /></Box>
                </Box>

                <Box component='div'
                  onClick={() => { SetActiveMenu("custometemplates") }}
                  className={`popup-left-menuitem ${activeMenu === 'custometemplates' ? 'active' : ''}`}
                >
                  <Typography component='span'>Custom Templates</Typography>
                  <Box component='div' className='popup-menuitem-icon'><img src={TemplateImage} alt="templates" width={30} /></Box>
                </Box>

                <Box component='div'
                  onClick={() => { SetActiveMenu("projects") }}
                  className={`popup-left-menuitem ${activeMenu === 'projects' ? 'active' : ''}`}
                >
                  <Typography component='span'>Your Project</Typography>
                  <Box component='div' className='popup-menuitem-icon'><img src={ProjectsImage} alt="your project" width={30} /></Box>
                </Box>

                <Box component='div'
                  onClick={() => { SetActiveMenu("exports") }}
                  className={`popup-left-menuitem ${activeMenu === 'exports' ? 'active' : ''}`}
                >
                  <Typography component='span'>Your Exports</Typography>
                  <Box component='div' className='popup-menuitem-icon'><img src={ExportImage} alt="your exports" width={30} /></Box>
                </Box>

                <Box component='div'
                  onClick={() => { SetActiveMenu("assets") }}
                  className={`popup-left-menuitem ${activeMenu === 'assets' ? 'active' : ''}`}
                >
                  <Typography component='span'>Your Assets</Typography>
                  <Box component='div' className='popup-menuitem-icon'><img src={AssetsImage} alt="your assets" width={30} /></Box>
                </Box>

                <Box component='div'
                  onClick={() => { SetActiveMenu("compress") }}
                  className={`popup-left-menuitem ${activeMenu === 'compress' ? 'active' : ''}`}
                >
                  <Typography component='span'>Your Compress</Typography>
                  <Box component='div' className='popup-menuitem-icon'><img src={CompressImage} alt="your compress" width={30} /></Box>
                </Box>

                <Box component='div'
                  onClick={() => { SetActiveMenu("transcodes") }}
                  className={`popup-left-menuitem ${activeMenu === 'transcodes' ? 'active' : ''}`}
                >
                  <Typography component='span'>Your Transcodes</Typography>
                  <Box component='div' className='popup-menuitem-icon'><img src={TransocodeImage} alt="your transcodes" width={30} /></Box>
                </Box>
              </Box>
              <Link to='/dashboard/projects' className="backup-to-dashboard">
                <ArrowBackIosNewIcon />
                <Typography component='span'>
                  Back to Dashboard
                </Typography>
              </Link>
            </Box>
          </Grid>
          <Grid item xs={6} md={8}>
            <Box className="ep-popup-rigt-contain">
              {activeMenu === 'stocks' && (<Stocks />)}
              {activeMenu === 'templates' && (<Templates page='empty-project' />)}
              {activeMenu === 'custometemplates' && (<Templates page='custometemplates' />)}
              {activeMenu === 'projects' && (<Projects />)}
              {activeMenu === 'exports' && (<Exports />)}
              {activeMenu === 'assets' && (<Assets />)}
              {activeMenu === 'compress' && (<Compress />)}
              {activeMenu === 'transcodes' && (<Transcodes />)}
            </Box>
          </Grid>
        </Grid>

      </Box>
    </Dialog>
  )
}