import React, { useCallback, useState, useEffect } from "react";
import { NavLink, Link, useSearchParams } from 'react-router-dom';
import { Box } from "@mui/material";
import Swal from 'sweetalert2';
import './signIn.scss';
import Logo from './../../assets/image/Logo.png';
import { GoogleIcon } from "../../Icons";
import { useLocation } from 'react-router-dom';

import { requestSignInCode, verifyLoginCode } from "../../axios/ApiProvider";
const BASE_FRONTEND_URL        = process.env.REACT_APP_BASE_FRONTEND_URL;
const BASE_FRONTEND_EDITOR_URL = process.env.REACT_APP_BASE_FRONTEND_EDITOR_URL;
const BASE_BACKEND_URL         = process.env.REACT_APP_BASE_BACKEND_URL;
const BASE_GOOGLE_CLIENT_ID    = process.env.REACT_APP_GOOGLE_CLIENT_ID;

export const SignIn = () => {
  const location = useLocation();
  const [countdown, setCountdown] = useState(0);
  const [codeSent, setCodeSent] = useState(false);
  const queryParams = new URLSearchParams(location.search);
  const projectUUID = queryParams.get('project_uuid');

  const [searchParams] = useSearchParams();
  const email = searchParams.get("email");

  const [accountInfo, setAccountInfo] = useState({
    email: email || "",
    code: ""
  });

  const GoogleSignInBtn = useCallback(() => {
    const googleAuthUrl = 'https://accounts.google.com/o/oauth2/v2/auth';
    const redirectUri = 'api/v1/oauth/google';

    const scope = ['https://www.googleapis.com/auth/userinfo.email', 'https://www.googleapis.com/auth/userinfo.profile'].join(' ');

    const params = {
      response_type: 'code',
      client_id: BASE_GOOGLE_CLIENT_ID,
      redirect_uri: `${BASE_BACKEND_URL}/${redirectUri}`,
      prompt: 'select_account',
      access_type: 'offline',
      scope
    };
    const urlParams = new URLSearchParams(params).toString();
    window.location = `${googleAuthUrl}?${urlParams}`;
  }, []);

  function validateEmail(email) {
    var re = /\S+@\S+\.\S+/;
    return re.test(email);
  }

  const requestCode = async () => {
    if (!validateEmail(accountInfo.email)) {
        window.toastr.error("Invalid email address.");
        return;
    }

    try {
        let result = await requestSignInCode(accountInfo.email);
        console.log("result:", JSON.stringify(result));

        if (result.state=="success") {  // Check if response status is in the range 200-299
            setCodeSent(true);
            setCountdown(300);
            window.toastr.success("Verification code sent to your email.");
        } else {
          Swal.fire({
            title: "Sign in failed",
            html: result.data.code || "Failed to send verification code.",
            showConfirmButton: true,
          }).then((result) => {
            console.log(result);
          })
          //window.toastr.error(result.data.code || "Failed to send verification code.");
        }
    } catch (error) {
        window.toastr.error("An error occurred. Please try again.");
    }
  };

  const verifyCode = async () => {
    try {
      let response = await verifyLoginCode(accountInfo.email, accountInfo.code);
      if (response.state=="success") {
        window.toastr.success("Login successful.");
        // Redirect or perform other actions on successful login

        if (projectUUID) {
          window.location = `${BASE_FRONTEND_EDITOR_URL}/${projectUUID}`;
        } else {
          console.log("response: ", BASE_FRONTEND_URL);
          window.location = `${BASE_FRONTEND_URL}/dashboard/templates`;
        }
      } else {
        window.toastr.error(response.data.code || "Invalid or expired code.");
      }
    } catch (error) {
      window.toastr.error("An error occurred during verification. Please try again.");
    }
  };

  const handleInputChange = (e) => {
    setAccountInfo({
      ...accountInfo,
      [e.target.name]: e.target.value
    });
  };

  useEffect(() => {
    let timer;
    if (codeSent && countdown > 0) {
      timer = setInterval(() => {
        setCountdown(prevCountdown => prevCountdown - 1);
      }, 1000);
    } else if (countdown === 0) {
      setCodeSent(false); // Optionally reset codeSent if countdown finishes
    }
    return () => clearInterval(timer);
  }, [codeSent, countdown]);

  useEffect(() => {
    if (codeSent) {
      const inputElement = document.getElementById('code_input');
      if (inputElement) inputElement.focus();
    }
  }, [codeSent]);

  return (
    <Box className="signUp-containner">
      <Box className="signup-left">
        <Box className='signup-left-containner'>
          <Box className='signup-left-content'>
            <Box className='signup-content-header'>
              <Link to={'..'}>
                <img src={Logo} alt='logo' />
              </Link>

              <Box className='signup-header-content'>
                Welcome back to VIDEOO
              </Box>

              <Box className='signup-header-footer'>
                Log in to your account and start creating
              </Box>
            </Box>

            <Box className='signup-content-body'>
              {projectUUID ? null : (
                <Box className='googleBtn' onClick={GoogleSignInBtn}>
                  <GoogleIcon /> Continue with Google
                </Box>
              )}
              {projectUUID ? (
                <Box className='signup-content-or'></Box>
              ) : (
                <Box className='signup-content-or'>OR</Box>
              )}

              <Box className='signup-content-input'>
                <input
                  placeholder="Enter your email address here"
                  data-testid="@email-input"
                  type="email"
                  name="email"
                  className='email-input'
                  id='email_input'
                  value={accountInfo.email}
                  onChange={handleInputChange}
                  readOnly={codeSent}
                />
                <label htmlFor='email' className='email-input-label'>
                  Email Address
                </label>
              </Box>

              {codeSent && (
                <Box className='signup-content-input'>
                  <input
                    placeholder="Enter the 6-digit code"
                    data-testid="@code-input"
                    type="text"
                    name="code"
                    className='email-input'
                    id='code_input'
                    value={accountInfo.code}
                    onChange={handleInputChange}
                  />
                  <label htmlFor='code' className='email-input-label'>
                    Verification Code
                  </label>
                </Box>
              )}

              {codeSent && (
                <Box className='signup-countdown'>
                  Resend code in: {Math.floor(countdown / 60)}:{String(countdown % 60).padStart(2, '0')}
                </Box>
              )}

              {!codeSent ? (
                <Box className='signup-Btn' onClick={requestCode}>
                  Send Code
                </Box>
              ) : (
                <Box className='signup-Btn' onClick={verifyCode}>
                  Verify Code
                </Box>
              )}

              <p className='signup-btn-footer-text'>
                {codeSent
                  ? "Enter the code sent to your email to sign in."
                  : "We'll email you a 6-digit code to sign in."}
              </p>
            </Box>
          </Box>

          <Box className='signup-left-footer'>
            <Box className='signup-content-footer'>
              <Box className='signup-content-footer-text'>
                By proceeding, you agree to our
                <Box>
                  <Link to='/terms'>Terms</Link> and <Link to='/privacy'>Privacy Policy</Link>
                </Box>
              </Box>

              <Box className='signup-content-footer-footer'>
                You don't have an account?
                <br />
                <NavLink to={`/signup/?project_uuid=${projectUUID || ''}`}>Create an account</NavLink>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>

      <Box className="signup-right"></Box>
    </Box>
  );
};
